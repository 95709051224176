exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-creators-index-js": () => import("./../../../src/pages/creators/index.js" /* webpackChunkName: "component---src-pages-creators-index-js" */),
  "component---src-pages-models-index-js": () => import("./../../../src/pages/models/index.js" /* webpackChunkName: "component---src-pages-models-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-creators-js": () => import("./../../../src/templates/creators.js" /* webpackChunkName: "component---src-templates-creators-js" */),
  "component---src-templates-forbidden-page-js": () => import("./../../../src/templates/forbidden-page.js" /* webpackChunkName: "component---src-templates-forbidden-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-model-js": () => import("./../../../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */),
  "component---src-templates-model-search-js": () => import("./../../../src/templates/model-search.js" /* webpackChunkName: "component---src-templates-model-search-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

